













import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { CommentsViewModel } from '../viewmodels/comments-viewmodel'

@Observer
@Component({
  components: {
    'comment-list': () => import('./components/comments/comment-list.vue'),
  },
})
export default class Comments extends Vue {
  @Provide() vm = new CommentsViewModel()
  @Watch('$route', { immediate: true }) tabChange(val) {
    const hash = this.$router.currentRoute.hash
    if (hash) {
      if (hash === '#comment') {
        setTimeout(() => {
          document.getElementById(`list-comment`)?.scrollIntoView({ behavior: 'smooth' })
        }, 300)
      }
    }
  }
}
