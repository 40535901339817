import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { CommentModel } from '@/models/post-model'
import { ProfileModel } from '@/models/profile-model'
import { apiService } from '@/services/api-services'
import { observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class CommentsViewModel {
  @observable userProfile?: ProfileModel
  @observable fetching = false
  @observable comments: CommentModel[] = []

  constructor() {
    this.init()
  }

  @asyncAction *init() {
    try {
      const userId = appProvider.router.currentRoute.params.userId
      const userProfiles = yield apiService.profiles.find({ unique_id: userId, _limit: 1 })
      if (userProfiles[0]) {
        this.userProfile = userProfiles[0]
        this.fetchComments()
      } else {
        snackController.commonError('Can not find user profile')
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *fetchComments() {
    try {
      this.fetching = true
      const comments = yield apiService.comments.fetchComments({ owner: this.userProfile?._id })
      this.comments = comments
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.fetching = false
    }
  }
}
